import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import '../App.css';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

// Helper function to reorder the letters after drag
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Initial words and shuffling them
const initialLetters = ['h', 'a', 'h', ' ', 'c', 'a', 'l', 'i', 'n', 'e', 'r', ' ', 'p','a', 'l', 'm', 'e', 'r', 'a', 'i', 'e'];
const correctWords = ['raphael aime charline']; // Set of correct words

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

export default function Anagramme() {
  const [letters, setLetters] = useState(initialLetters);
  const [isCorrect, setIsCorrect] = useState(false);

	let navigate = useNavigate(); 
  	const routeChange = () =>{ 
    	let path = 'geography'; 
   	 navigate(path);
 	 }

  const checkIfCorrect = (letters) => {
    // Join the letters into a string and check if it matches any correct word
    const currentWord = letters.join('');
    if (correctWords.includes(currentWord)) {
      setIsCorrect(true); // Puzzle solved
    } else {
      setIsCorrect(false); // Not yet correct
    }
  };

  const onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedLetters = reorder(letters, result.source.index, result.destination.index);
    setLetters(reorderedLetters);
    
    // Check if the new arrangement is correct
    checkIfCorrect(reorderedLetters);
  };

  return (
    <div className="puzzle-container">
      Tu es à présent au deuxième niveau de sécurité. Tu constates que des mots étranges y sont notés, et que les lettres peuvent être déplacées. Un indice : "chartichaut, dans cet ordre, et le lien au milieu". <br/> <br/>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="letters" direction="horizontal">
          {(provided) => (
            <div
              className="letters-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {letters.map((letter, index) => (
                <Draggable key={index} draggableId={`letter-${index}`} index={index}>
                  {(provided) => (
                    <div
                      className="letter"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {letter}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Show success message when the puzzle is solved */}
      {isCorrect ? (
	<div>
	<br/>Code correct ! <br/><br/>
        <Button variant="contained" onClick={routeChange}>Continuer</Button>
	</div>
      ) : (
        <h2>Quelle peut bien être la signification de ce code ?</h2>
      )}
    </div>
  );
}
