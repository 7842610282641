import React, {useState} from 'react';
import '../App.css';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import {JigsawPuzzle} from "react-jigsaw-puzzle/lib";
import "react-jigsaw-puzzle/lib/jigsaw-puzzle.css";
import photo from "../Images/bisou.jpg"

export default function Puzzle() {

	let navigate = useNavigate(); 
	const [visible, setVisible] = useState(false);

  	const routeChange = () =>{ 
    	let path = '/anagram'; 
   	 navigate(path);
 	 }

	const appear = () =>{
	setVisible(true);
	}

        return (
	<div className="Background">
	<div className="OutsideBox">
	<div className="InsideBox">
	Te voilà dans le premier niveau de sécurité. M. Chat a encodé l'accès à son ordinateur à une sorte de puzzle... Il s'agit vraisemblablement d'une photo très importante pour lui. Si tu parviens à la reconstituer, peut-être trouveras-tu un moyen de franchir le premier palier !

	<div style={{height : 30 + 'em', width : 30 + 'em', margin : "auto", padding : 40 + "px"}}>
            <JigsawPuzzle
  	imageSrc={photo}
 	 rows={4}
 	 columns={4}
  	onSolved={appear}
	/>
        </div>
	<div style={{ paddingBottom : 30 + "px"}}>
	{ visible && <Button variant="contained" onClick={routeChange}>Entrer dans le système</Button> }
	</div>
	</div>
    	</div>
	</div>

        );
}