import React, {Component} from 'react';
import logo from '../Images/Treasure.jpg';
import '../App.css';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export default function Treasure() {

        return (
            <div className="App">
      		<header className="App-header">
				<img src={logo} className="App-logo2" alt="logo" />
			</header>
    </div>

        );
}