import React, {Component} from 'react';
import logo from '../Images/Chartichaut.png';
import '../App.css';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export default function Geography() {

	let navigate = useNavigate(); 
  	const routeChange = () =>{ 
    	let path = 'entrance'; 
   	 navigate(path);
 	 }

        return (
	<div className="Background">
	<div className="OutsideBox">
	<div className="InsideBox">
          Te voilà devant le code de déverrouillage de l'ordinateur de M. Chat. Avant même de passer les niveaux de sécurité, il faut trouver son code. Il s'agit visiblement d'un lieu important pour lui, le lieu d'un commencement probablement.
        </div>
	<iframe src="https://lockee.fr/o/mqxrx10k" height="500" width="350" frameborder="0"></iframe>
	</div>
	</div>

        );
}