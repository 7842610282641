import React, {Component} from 'react';

class Home extends Component{

    render = () => {
        return (
            <div className="error_background">
                <div className="error_text">
                Eeeh non !
                </div>
            </div>
        )
    }
}

export default Home;