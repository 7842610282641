import React, {Component} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home.js';
import Puzzle from './Puzzle.js';
import Error from './Error.js';
import Geography from './Geography.js';
import Anagram from './Anagram.js';
import CoffreFort from './CoffreFort.js';
import Treasure from './Treasure.js';

class Navbar extends Component{

    render = () => {
        return (
            <div>
                <div className="background">
                    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
		  <Route path="/entrance" element={<Puzzle />} />
		  <Route path="/geography" element={<Geography />} />
		  <Route path="/anagram" element={<Anagram />} />
		  <Route path="/coffrefort" element={<CoffreFort />} />
		  <Route path="/Treasure" element={<Treasure />} />
		  <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
                </div>
                <div className="background_image">
                </div>
            </div>
        )
    }
}

export default Navbar