import React, {Component} from 'react';
import logo from '../Images/Chartichaut.png';
import '../App.css';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export default function Home() {

	let navigate = useNavigate(); 
  	const routeChange = () =>{ 
    	let path = 'geography'; 
   	 navigate(path);
 	 }

        return (
            <div className="App">
      		<header className="App-header">
		<div className="BoxLogo">
        	<img src={logo} className="App-logo2" alt="logo" />
		</div>
            <div className="Content">
          Bienvenue Charline. Nous avons besoin de toi. Pour obtenir l'accès au lieu de la vente aux enchères, tu dois hacker l'ordinateur du responsable de la sécurité, un certain M. Chat. Lorsque tu es prête à commencer, cliques sur le bouton ci-dessous ! Chartichaut compte sur toi !
        </div>
	<Button variant="contained" onClick={routeChange}>Hacker le site</Button>

      </header>
    </div>

        );
}