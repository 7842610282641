import React, {Component} from 'react';
import logo from '../Images/lock.png';
import '../App.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';
import { useState } from 'react';

export default function CoffreFort() {

	const [n1, setN1] = useState(0);
	const [n2, setN2] = useState(0);
	const [n3, setN3] = useState(0);
	const [n4, setN4] = useState(0);
	const [found, setFound] = useState(true);
	
	let navigate = useNavigate(); 
		
	let checkValid = () => {
	if(n1 == 19 && n2 == 21 && n3 == 13 && n4 == 14){
		let path = 'success'; 
		 navigate(path);
	}
	else{
		setFound(false);
	}
	}
  	const routeChange = () =>{ 
    	let path = 'geography'; 
   	 navigate(path);
 	 }

        return (
        <div className="Background2">
		<LockIcon color="primary" sx={{ fontSize: 150 }}/>
		<br/>
		<div className="OutsideBox">
		<div className="InsideBox">
		Quel est le code du coffre-fort de Gustave Devrin ?
            <div className="Content2">
				<Grid container spacing={2} sx={{
				justifyContent: "center",
				alignItems: "center",}}>
					<Grid item xs={2}>
					<TextField id="first" type="number" slotProps={{ inputLabel: { shrink: true, }, }} onChange = {e => setN1(e.target.value)}/>
					</Grid>
					<Grid item xs={2}>
					<TextField id="second" type="number" slotProps={{ inputLabel: { shrink: true, }, }} onChange = {e => setN2(e.target.value)} />
					</Grid>
					<Grid item xs={2}>
					<TextField id="third" type="number" slotProps={{ inputLabel: { shrink: true, }, }} onChange = {e => setN3(e.target.value)} />
					</Grid>
					<Grid item xs={2}>
					<TextField id="fourth" type="number" slotProps={{ inputLabel: { shrink: true, }, }} onChange = {e => setN4(e.target.value)} />
					</Grid>
				</Grid>
        </div>
		{ !found && <div className = "Error" > Faux ! </div>}
	<Button variant="contained" onClick={checkValid}>Tester le code</Button>

      </div> </div> </div>

        );
}